import axios from 'axios'

class DefaultApiService {
    API_URL = process.env.VUE_APP_API_URL;

    list (endpoint, params) {
      return axios.get(this.API_URL + endpoint, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    get (endpoint, id, params) {
      return axios.get(this.API_URL + endpoint + '/' + id, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    create (endpoint, data, params) {
      return axios.post(this.API_URL + endpoint, data, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    request (endpoint, data, params) { // Only used to request usage stats csv
      return axios.post(this.API_URL + endpoint, data, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    resend (endpoint, id, params) { // Only used to trigger resend of invitation mail
      return axios.get(this.API_URL + endpoint + '/' + id, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    update (endpoint, data, params) {
      return axios.put(this.API_URL + endpoint, data, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    delete (endpoint, id, params) {
      return axios.delete(this.API_URL + endpoint + '/' + id, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    count (endpoint, params) {
      return axios(this.API_URL + endpoint, { params: params, withCredentials: true }).then(
        response => {
          return response.data.data
        },
        error => this.errorHandler(error)
      )
    }

    errorHandler (error) {
      const customError = {}
      if (error.response) {
        customError.message = error.response.data.exception
        customError.status = error.response.status
        customError.headers = error.response.headers
      } else {
        customError.noResponse = true
        customError.message = error.message
      }
      if (error.request) {
        customError.request = error.request
      }
      customError.config = error.config
      customError.json = error.toJSON()
      return Promise.reject(customError)
    }
}

export default new DefaultApiService()
