import Vue from 'vue'
import { invitationServiceForUserView } from '@/services/invitation.service'
import { accountServiceForUserView } from '@/services/account.service'
import { voucherServiceForUserView } from '@/services/voucher.service'
import { usageServiceForUserView } from '@/services/usage.service'

const state = () => ({
  invitations: [],
  vouchers: [],
  accounts: [],
  actions: []
})

const getters = {
  invitationsPending: (state) => {
    return state.invitations.filter(invitation => invitation.state === 'pending')
  },
  invitationsArchived: (state) => {
    return state.invitations.filter(invitation => invitation.state !== 'pending')
  },
  accountsActive: (state) => {
    return state.accounts.filter(account => account.state === 'active')
  },
  accountsArchived: (state) => {
    return state.accounts.filter(account => account.state !== 'active')
  },
  vouchersArchived: (state) => {
    return state.vouchers.filter(voucher => voucher.state !== 'pending')
  },
  getActionForCC: (state) => (username) => {
    const action = state.actions.find(action => action.username === username && action.targetkey === 'CC')
    if (action) {
      return action.action
    } else {
      return ''
    }
  },
  getActionForJH: (state) => (username) => {
    const action = state.actions.find(action => action.username === username && action.targetkey === 'JH')
    if (action) {
      return action.action
    } else {
      return ''
    }
  }
}

const actions = {
  getInvitations ({ commit }) {
    invitationServiceForUserView.list().then((invitations) => {
      commit({
        type: 'setInvitations',
        invitations: invitations.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  getVouchers ({ commit }) {
    voucherServiceForUserView.list().then((vouchers) => {
      commit({
        type: 'setVouchers',
        vouchers: vouchers.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  updateInvitation ({ commit }, payload) {
    return invitationServiceForUserView.update(payload.invitation).then(
      response => {
        if (response.state === 'accepted') {
          commit({ type: 'setInvitation', invitation: response })
          commit({ type: 'addAccount', account: response.accountCreated })
        } else { // invite declined
          commit({ type: 'setInvitation', invitation: response })
        }
        return response
      }
    ).catch(error => {
      this.dispatch('user/getInvitations')
      this.dispatch('user/getAccounts')
      throw error
    })
  },
  redeemVoucher ({ commit }, payload) {
    return voucherServiceForUserView.redeem(payload.voucherCode).then(
      response => {
        if (response.state === 'accepted') {
          commit({ type: 'addVoucher', voucher: response })
          commit({ type: 'addAccount', account: response.accountCreated })
        } else { // Something went wrong
          console.log(response)
        }
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  getUsages ({ commit }, payload) {
    return usageServiceForUserView.getCurrentForAccount(payload.targetId).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  getAccounts ({ commit }) {
    accountServiceForUserView.list().then((accounts) => {
      commit({
        type: 'setAccounts',
        accounts: accounts.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  buildActionForCC ({ commit }, payload) {
    return accountServiceForUserView.buildTokenForCC(payload.account).then(
      response => {
        commit({ type: 'addAction', username: payload.account, targetkey: 'CC', action: response.endpoint + '?login-token=' + response.token })
        return response.endpoint + '?login-token=' + response.token
      }
    ).catch(error => {
      throw error
    })
  },
  buildActionForJH ({ commit }, payload) {
    return accountServiceForUserView.buildTokenForJH(payload.account, payload.tokenTarget).then(
      response => {
        commit({ type: 'addAction', username: payload.account, targetkey: 'JH', action: response.endpoint + '?' + response.token })
        return response.endpoint + '?' + response.token
      }
    ).catch(error => {
      throw error
    })
  }
}

const mutations = {
  setInvitations (state, payload) {
    state.invitations = payload.invitations
  },
  setInvitation (state, payload) {
    state.invitations.find((invitation, invitationIndex) => {
      if (invitation.invitationId === payload.invitation.invitationId) {
        Vue.set(state.invitations, invitationIndex, payload.invitation)
      }
    })
  },
  setVouchers (state, payload) {
    state.vouchers = payload.vouchers
  },
  setVoucher (state, payload) {
    state.vouchers.find((voucher, voucherIndex) => {
      if (voucher.voucherId === payload.voucher.voucherId) {
        Vue.set(state.vouchers, voucherIndex, payload.voucher)
      }
    })
  },
  setAccounts (state, payload) {
    state.accounts = payload.accounts
  },
  addAccount (state, payload) {
    state.accounts.push(payload.account)
  },
  addVoucher (state, payload) {
    state.vouchers.push(payload.voucher)
  },
  addAction (state, payload) {
    state.actions.push({ username: payload.username, targetkey: payload.targetkey, action: payload.action })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
