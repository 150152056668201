export default {
  watch: {
    pageTitle (newPageTitle) {
      if (newPageTitle) {
        document.title = newPageTitle
      }
    }
  },
  created () {
    if (this.pageTitle) {
      document.title = this.pageTitle
    }
  }
}
