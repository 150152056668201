<template>
  <div>
    <div v-if="language === 'de'">
      <div class="text-justify">
      Von der Universitätsleitung verabschiedet am 25.11.2020

      <h2>Präambel</h2>
      <p>
        Die Informationstechnologie ist ein grundlegendes Werkzeug in Forschung, Lehre, Studium und Weiterbildung, für die Bibliotheksnutzung
        sowie für zahlreiche Aufgaben und Tätigkeiten in der Verwaltung und im technischen Betrieb der Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU).
        Diese Richtlinie soll die möglichst störungsfreie, ungehinderte und sichere Nutzung der Anlagen und Einrichtungen, Systeme und Verfahren der
        Informationstechnologie der FAU sowohl im lokalen Betrieb wie auch im vernetzten Verbund gewährleisten. Sie regelt insbesondere Rechte und Pflichten
        der Nutzerinnen und Nutzer sowie die Aufgaben, Rechte und Pflichten der jeweiligen Systembetreiber.
      </p>

      <p>Diese Richtlinie</p>
      <ul>
        <li>orientiert sich an den gesetzlich festgelegten Aufgaben der Hochschulen sowie an ihrem Mandat zur Wahrung der akademischen Freiheit,</li>
        <li>stellt Grundregeln für einen ordnungsgemäßen Betrieb der Informationstechnologie auf,</li>
        <li>weist hin auf die zu wahrenden Rechte Dritter (z. B. Softwarelizenzen, Auflagen der Netzbetreiber, Datenschutzaspekte),</li>
        <li>verpflichtet zu korrektem Verhalten und zum ökonomischen Gebrauch der angebotenen Ressourcen,</li>
        <li>klärt auf über eventuelle Maßnahmen bei Verstößen gegen die Nutzungsregelungen der Informationstechnologie der FAU.</li>
      </ul>

      <h2><a name="1"></a>§ 1&nbsp;Geltungsbereich</h2>
      <ol type="decimal">
        <li>
          Diese Richtlinie gilt für die Nutzung von Rechnern, mobilen Endgeräten, Speichern, Software, kabelbasierten und drahtlosen Datennetzen und anderen
          technischen Ausstattungen (IT-Infrastruktur), die an der FAU oder außerhalb an anderen Stellen durch Inanspruchnahme Dritter für die Zwecke der
          elektronischen Informationsverarbeitung der FAU eingesetzt werden, sowie für die auf der Grundlage dieser Infrastruktur betriebenen Systeme und
          Verfahren im Bereich der wissenschaftlichen und nichtwissenschaftlichen elektronischen Informationsverarbeitung.
        </li>
        <li>
          Diese Richtlinie gilt im Gesamtbereich der FAU für alle nutzungsberechtigten Personen und
          Einrichtungen (§ 2 Abs. 1 und 2) und Systembetreiber (§ 5 Abs. 1).
        </li>
      </ol>

      <h2><a name="2"></a>§ 2 Nutzungsberechtigte Personen und Einrichtungen, Nutzungszweck</h2>
      <ol>
        <li>Nutzungsberechtigt hinsichtlich der IT-Infrastruktur, Systeme und Verfahren der FAU nach § 1 Abs. 1 sind insbesondere
          <ol style="list-style-type: lower-alpha;" type="lower-alpha">
            <li>Mitglieder der FAU,</li>
            <li>Mitglieder von Hochschulen, die im Rahmen des Regionalkonzepts des Regionalen Rechenzentrums Erlangen (RRZE) die Leistungen des RRZE in Anspruch zu nehmen berechtigt sind,</li>
            <li>Mitglieder anderer Hochschulen bezüglich der für diese Hochschulen bereitstehenden Hochleistungsrechnersysteme,</li>
            <li>Kooperations- und Vertragspartner der FAU, soweit es der Kooperation dient bzw. für die Erfüllung übernommener vertraglicher Pflichten erforderlich ist,</li>
            <li>juristische Personen des Privatrechts (eingetragene Vereine, Stiftungen, Genossenschaften u.a.), zu deren satzungsgemäßen Zwecken die Förderung der FAU und ihrer gesetzlichen Aufgaben gehört, nach Maßgabe einer bestehenden Kooperationsvereinbarung,</li>
            <li>juristische Personen, an denen die FAU als Gesellschafter beteiligt ist,</li>
            <li>öffentliche Einrichtungen, insbesondere Forschungs- und Bildungseinrichtungen, Studentenwerke und Behörden.</li>
          </ol>
        </li>
        <li>Anderen Personen oder wissenschaftsnahen Einrichtungen kann die Nutzung gestattet werden, wenn dies den Aufgaben der FAU dient oder damit in engem Zusammenhang steht und soweit dadurch die Nutzung der in Abs. 1 genannten vorrangigen Nutzer nicht beeinträchtigt wird.</li>
        <li>Die IT-Infrastruktur, Systeme und Verfahren der FAU stehen den nutzungsberechtigten Personen und Einrichtungen zur Erfüllung der Aufgaben in Forschung, Lehre, Studium, Aus- und Weiterbildung, für die sonstigen in Art. 2 des Bayerischen Hochschulgesetzes genannten Hochschulaufgaben und für Verwaltungsaufgaben zur Verfügung.</li>
      </ol>

      <h2><a name="3"></a>§ 3 Formale Nutzungsberechtigung</h2>
      <ol>
        <li>
          Wer die IT-Infrastruktur, Systeme und Verfahren der FAU nutzen will, bedarf einer Nutzungsberechtigung (Abs. 2). Soweit sich die hierfür
          erforderlichen Daten nicht bereits aus automatisierten Verzeichnissen ergeben, ist ein Antrag zu stellen. Ausgenommen sind Systeme und Verfahren,
          die für einen anonymen Zugang eingerichtet sind (z.B. Informationsdienste, Bibliotheksdienste, kurzfristige Gastkennungen bei Tagungen).
        </li>
        <li>
          Für die Erteilung einer Nutzungsberechtigung dürfen nur solche Angaben erhoben bzw. verwendet werden, die für die Entscheidung über den Antrag
          unmittelbar erforderlich sind. Regelmäßig erhoben werden können: Name, Vorname, Geburtsdatum, Geschlecht, Zugehörigkeit zu einer Organisationseinheit,
          Beschreibung des Nutzungszwecks, Unterschrift des Antragstellers. Die personenbezogenen Angaben sind durch Vorlage eines amtlichen Lichtbildausweises
          oder hilfsweise durch andere amtliche Dokumente nachzuweisen. Die Systembetreiber (§ 5 Abs. 1) können auch andere für die sichere Feststellung der
          Personenidentität geeignete Verfahren für zulässig erklären.
        </li>
        <li>
            Über den Antrag entscheiden die jeweiligen Systembetreiber (§ 5 Abs. 1). Sie können die Erteilung der Berechtigung von bestimmten Kriterien
            (Nachweis bestimmter Kenntnisse über die Nutzung, Zugehörigkeit zu einem bestimmten Nutzungstyp, Nutzungs- bzw. Weitergaberegelungen auf der Grundlage
            entsprechender außenwirtschaftsrechtlicher Bestimmungen) abhängig machen und mit nutzungsbezogenen Auflagen versehen.
        </li>
        <li>Die Nutzungsberechtigung ist zu versagen, wenn
          <ol style="list-style-type: lower-alpha;">
            <li>die Voraussetzungen des § 2 Abs. 1 und 2 nicht erfüllt sind,</li>
            <li>das Vorhaben nicht mit den Nutzungszwecken nach § 2 Abs. 3 vereinbar ist,</li>
            <li>kein sachlicher Grund für die Erteilung der Nutzungsberechtigung vorgetragen wird,</li>
            <li>Tatsachen die Annahme rechtfertigen, dass die oder der zu Berechtigende ihren bzw. seinen Pflichten als Nutzerin oder Nutzer nach § 4 nicht nachkommen wird,</li>
            <li>die Kapazitäten der Ressourcen, deren Nutzung beantragt wird, wegen einer bereits bestehenden Auslastung für die beabsichtigten Arbeiten nicht ausreichen, für spezielle Zwecke reserviert oder offensichtlich ungeeignet sind,</li>
            <li>durch die zu benutzenden Ressourcen eine Gefährdung anderer Systeme, Datennetze oder Schutzgüter anderer Personen (Personendaten, Arbeitsmaterialien und -ergebnisse etc.) zu erwarten ist.</li>
          </ol>
        </li>
        <li>
          Die Nutzungsberechtigung kann vorübergehend oder dauerhaft entzogen oder eingeschränkt werden, wenn einer der Versagungsgründe des Abs. 4
          nachträglich eintritt oder die Nutzerin bzw. der Nutzer sich mit einem fälligen Nutzungsentgelt für einen längeren Zeitraum als zwei Monate im
          Verzug befindet.
        </li>
        <li>
          Die nutzungsbeschränkenden Maßnahmen nach Abs. 5 richten sich nach dem Gebot der Verhältnismäßigkeit. Die Verfahrensbestimmungen nach § 6 sind zu
          beachten. Straf-, disziplinar- oder arbeitsrechtliche Konsequenzen bzw. Exmatrikulation wegen eines Fehlverhaltens sind durch den Nutzungsausschluss
          nicht ausgeschlossen.
        </li>
      </ol>

      <h2><a name="4"></a>§ 4 Rechte und Pflichten der Nutzerinnen und Nutzer</h2>
      <ol>
        <li>
          Die Nutzerinnen und Nutzer haben das Recht, die IT-Infrastruktur, Systeme und Verfahren der Informationstechnologie der FAU zu nutzen,
          jedoch nur zu den in § 2 Abs. 3 genannten Zwecken und nur nach Maßgabe dieser sowie gegebenenfalls im Einzelfall erlassener weiterer
          Nutzungsbestimmungen und Dienstvereinbarungen. Dabei ist jegliche Art der missbräuchlichen Nutzung zu unterlassen. Dazu zählt insbesondere die
          Nutzung zu strafbaren oder sonstigen rechtswidrigen Handlungen sowie
          <ol style="list-style-type: lower-alpha;">
            <li>der Betrieb von eigener Hardware im Datennetz der FAU, sofern diese dafür nicht ausdrücklich durch den jeweiligen Systembetreiber (§ 5 Abs. 1) freigegeben ist,</li>
            <li>die Installation, der Betrieb und die Nutzung von nicht mit den wahrzunehmenden Aufgaben in Zusammenhang stehenden Systemen, Verfahren und Programmen,</li>
            <li>die Installation und Nutzung lizenzpflichtiger Software ohne gültige Lizenz,</li>
            <li>die Nutzung zu privaten Zwecken, sofern diese nicht nur in geringfügigem Umfang erfolgt, oder den Betrieb und die Sicherheit der IT-Infrastruktur, Systeme und Verfahren oder den Betriebsfrieden bzw. das gute soziale Miteinander gefährdet. Eine Änderung der Netzwerkkonfiguration für private Zwecke ist stets ausgeschlossen.</li>
          </ol>
        </li>
        <li>Es wird ausdrücklich darauf hingewiesen, dass insbesondere folgende Verhaltensweisen nach dem Strafgesetzbuch unter Strafe gestellt sind:
          <ol style="list-style-type: lower-alpha;">
            <li>Ausspähen von Daten (§ 202a StGB),</li>
            <li>Abfangen von Daten (§ 202b StGB),</li>
            <li>Vorbereiten des Ausspähens und Abfangens von Daten (§ 202c StGB),</li>
            <li>Datenhehlerei (§ 202d StGB),</li>
            <li>Datenveränderung (§ 303a StGB) und Computersabotage (§ 303b StGB),</li>
            <li>Computerbetrug (§ 263a StGB),</li>
            <li>Verbreitung pornographischer Darstellungen (§§ 184 ff. StGB), insbesondere Verbreitung, Erwerb und Besitz kinderpornographischer Schriften (§ 184b StGB) und die Verbreitung pornographischer Darbietungen durch Rundfunk oder Telemedien (§ 184d StGB),</li>
            <li>Verbreitung von Propagandamitteln verfassungswidriger Organisationen (§ 86 StGB) und Volksverhetzung (§ 130 StGB),</li>
            <li>Ehrdelikte wie Beleidigung oder Verleumdung (§§ 185 ff. StGB).</li>
          </ol>
        </li>
        <li>Die Nutzerinnen und Nutzer sind verpflichtet,
          <ol style="list-style-type: lower-alpha;">
            <li>bei der Nutzung von Software, Dokumentationen und anderen Daten die gesetzlichen Regelungen (Urheberrechtsschutz, Copyright) einzuhalten,</li>
            <li>insbesondere Software, Dokumentationen und Daten, soweit nicht ausdrücklich erlaubt, weder zu kopieren noch weiterzugeben noch zu anderen als den erlaubten, insbesondere nicht zu gewerblichen Zwecken zu nutzen. Auf die Strafbewehrung von Urheberrechtsverletzungen, z.B. durch rechtswidrige Vervielfältigung von Software bzw. Anbieten von Filmen oder Musikstücken nach §§ 106ff. UrhG wird ausdrücklich hingewiesen,</li>
            <li>regelmäßige Überprüfungen zuzulassen, welche Software auf dienstlichen Geräten installiert ist und auf Aufforderung eine für diese Überprüfung geeignete Software auf dem Gerät zu installieren.</li>
          </ol>
        </li>
        <li>Die Nutzerinnen und Nutzer sind des Weiteren verpflichtet,
          <ol style="list-style-type: lower-alpha;">
            <li>ausschließlich mit Nutzungskennungen zu arbeiten, deren Nutzung ihnen gestattet wurde; insbesondere ist die Arbeit unter fremden Kennungen untersagt. Die Weitergabe von Zugangsdaten (z.B. Nutzungskennung in Verbindung mit Passwort) oder deren Hinterlegung in Anlagen und Geräten, die auch eine Nutzung durch andere zulassen, ist grundsätzlich nicht gestattet (Ausnahme: Funktionskennungen, die ausdrücklich zur zweckgebundenen Nutzung durch mehrere Personen vorgesehen sind und deren Weitergabe durch den Hauptnutzer der Kennung dokumentiert wird),</li>
            <li>den Zugang zur IT-Infrastruktur, zu den Systemen und Verfahren der FAU durch ein geheim zu haltendes Passwort oder ein gleich- bzw. höherwertiges Verfahren zu schützen,</li>
            <li>Vorkehrungen zu treffen, damit unberechtigten Dritten der Zugang verwehrt wird; dazu gehört es insbesondere, ein dem aktuellen Stand der Technik entsprechendes Passwort zu verwenden und sich am Ende der Nutzung ordnungsgemäß vom benutzten System oder Verfahren abzumelden bzw. diese bei Abwesenheit vom Arbeitsplatz für die unbefugte Nutzung zu sperren,</li>
            <li>ihnen bekannt gewordene, möglicherweise sicherheitsrelevante Vorfälle unverzüglich dem örtlichen DV-Beauftragten und dem RRZE unter der E-Mail-Adresse <a href="mailto:abuse@fau.de">abuse@fau.de</a> zu melden.</li>
          </ol>
        </li>
        <li>Die Nutzerinnen und Nutzer tragen die Verantwortung für alle Aktionen, die unter ihren Zugangsdaten vorgenommen werden, und zwar auch dann, wenn diese Aktionen durch Dritte vorgenommen werden, denen sie den Zugang in haftungsrechtlich vorwerfbarer Weise ermöglicht haben. In einem derartigen Fall ist die FAU auch berechtigt, nachträglich von ihnen die Nutzungsentgelte zu verlangen, die der Dritte bei rechtmäßiger Nutzung hätte zahlen müssen.</li>
        <li>Die Nutzerinnen und Nutzer sind verpflichtet, darauf zu achten, dass sie die vorhandenen Betriebsmittel (Arbeitsplätze, CPU-Kapazität, Speicherplatz, Leitungskapazitäten, Peripheriegeräte und Verbrauchsmaterial) verantwortungsvoll und ökonomisch sinnvoll nutzen. Sie sind verpflichtet, Beeinträchtigungen des Betriebs, soweit sie vorhersehbar sind, zu unterlassen und nach bestem Wissen alles zu vermeiden, was Schaden an der IT-Infrastruktur, den Systemen und Verfahren der FAU oder bei anderen Nutzerinnen und Nutzer verursachen kann.</li>
        <li>Den Nutzerinnen und Nutzern ist es untersagt, ohne Einwilligung des zuständigen Systembetreibers (§ 5 Abs. 1)
          <ol style="list-style-type: lower-alpha;">
            <li>Eingriffe in die Hardware-Installationen vorzunehmen oder vorhandene Schnittstellen für Erweiterungen der vorhandenen Systeminfrastruktur zur verwenden (z. B. Anbindung von Proxyservern, Switches, Access-Points…),</li>
            <li>die Konfigurationen der Systeme (Betriebssysteme, Datennetze o. ä.) und Verfahren zu verändern.</li>
          </ol>
        </li>
        <li>Die Nutzerinnen und Nutzer sind verpflichtet, Vorhaben, in deren Rahmen die Verarbeitung personenbezogener Daten stattfinden soll, nur nach den datenschutzrechtlichen Bestimmungen sowie der Datenschutz-Geschäftsordnung der FAU durchzuführen. Dies gilt auch dann, wenn für die Verarbeitung personenbezogener Daten IT-Dienste von Dritten (z. B. Cloud-Dienste) genutzt werden.</li>
        <li>Zur Aufdeckung missbräuchlicher Nutzung und in nutzungsbedingten Störungsfällen sind von den Nutzerinnen und Nutzern die Rechte der Systembetreiber (§ 5 Abs. 1) und die entsprechenden Verfahrensbestimmungen nach § 6 unmittelbar zu beachten.</li>
        <li>Die Nutzerinnen und Nutzer sind verpflichtet,
          <ol style="list-style-type: lower-alpha;">
            <li>neben dieser Richtlinie vom CIO-Gremium der FAU oder von den jeweiligen Systembetreibern erlassene ergänzende Nutzungsbedingungen und Richtlinien zu beachten,</li>
            <li>im Verkehr mit Rechnern und Netzen anderer Betreiber deren Benutzungs- und Zugriffsrichtlinien einzuhalten.</li>
          </ol>
        </li>
      </ol>

      <h2><a name="5"></a>§ 5&nbsp;Rechte und Pflichten der Systembetreiber</h2>
      <ol>
        <li>Systembetreiber sind neben dem RRZE auch alle weiteren Organisationseinheiten der FAU (Fakultäten, Fachbereiche, Lehrstühle, Zentrale Einrichtungen, Betriebseinheiten und weitere Untereinheiten), soweit diese selbst oder mit Hilfe anderer innerhalb oder außerhalb der FAU Anlagen und Einrichtungen, Systeme und Verfahren zur elektronischen Informationsverarbeitung nach § 1 Abs. 1 betreiben oder zur Nutzung anbieten. Sind mehrere Organisationseinheiten der FAU inhaltlich, technisch und/oder organisatorisch am Systembetrieb beteiligt, so verständigen sich diese auf einen verantwortlichen Systembetreiber im Sinne dieser Richtlinie. Für den ordnungsmäßigen Systembetrieb innerhalb der Organisationseinheiten der FAU im Sinne dieser Richtlinie verantwortliche Personen sind deren Leiterinnen und Leiter. Es ist nur eine Aufgabendelegation, beispielsweise auf technisches Personal (Administratoren) zulässig, nicht jedoch eine Delegation im Sinne einer Letztverantwortung.</li>
        <li>Die Systembetreiber haben im Rahmen ihrer Aufgaben ausschließlich die IT-Infrastruktur, Systeme und Verfahren der FAU zu nutzen. Insbesondere dürfen keine Systeme und Verfahren in Betrieb genommen werden, für deren Einsatz es an der FAU bereits geeignete Systeme und Verfahren gibt, die alternativ (mit-)genutzt werden können. Dies gilt auch dann, wenn Kosten für die (Mit-)Nutzung entstehen. Bei Zweifelsfällen und über Ausnahmen entscheidet das CIO-Gremium.</li>
        <li>
          Die Systembetreiber sind berechtigt und verpflichtet, über erteilte Nutzungsberechtigungen entsprechende Nachweise zu führen.
          Die Unterlagen und Angaben, die bei der Beantragung bzw. Verlängerung von Nutzungsberechtigungen erstellt bzw. erhoben werden sowie etwaige
          anfallende Verbrauchsdaten können maschinell gespeichert werden und sind nach Auslaufen der Berechtigung zu löschen. Davon ausgenommen sind Daten,
          für die bestimmte Aufbewahrungspflichten gelten (z.B. Abrechnungsdaten).
        </li>
        <li>Die Systembetreiber sind berechtigt, die Inanspruchnahme der IT-Infrastruktur, Systeme und Verfahren durch einzelne Nutzerinnen und Nutzer zu dokumentieren und auszuwerten, jedoch nur soweit dies erforderlich ist
          <ol style="list-style-type: lower-alpha;">
            <li>zur Gewährleistung eines ordnungsgemäßen Systembetriebs,</li>
            <li>zur Ressourcenplanung und Systemadministration,</li>
            <li>zum Schutz der personenbezogenen Daten anderer Nutzerinnen und Nutzer,</li>
            <li>zu Abrechnungszwecken,</li>
            <li>für das Erkennen und Beseitigen von Störungen sowie</li>
            <li>zur Aufklärung und Unterbindung rechtswidriger oder missbräuchlicher Nutzung.</li>
          </ol>
        </li>
        <li>
          Die Systembetreiber tragen in angemessener Weise, insbesondere in Form regelmäßiger Stichproben, zum Vorbeugen, Verhindern bzw. Aufdecken von
          Missbräuchen bei. Hierfür sind sie insbesondere berechtigt, Passwörter und Nutzungsdaten zu prüfen und notwendige Schutzmaßnahmen, z. B. Änderungen
          leicht zu erratender Passwörter, durchzuführen, um die IT-Infrastruktur, die Systeme und Verfahren und Nutzungsdaten vor unberechtigten Zugriffen
          Dritter zu schützen. Bei erforderlichen Änderungen der Passwörter und sonstigen nutzungsrelevanten Schutzmaßnahmen sind die Nutzerinnen und Nutzer
          hiervon unverzüglich in Kenntnis zu setzen.
        </li>
        <li>Die Systembetreiber sind, soweit dies zur Beseitigung von Störungen oder zur Aufklärung und Unterbindung von Missbräuchen erforderlich ist, unter Beachtung des Datengeheimnisses zur Einsicht in Nutzungsdateien und zum Ergreifen abwehrender Maßnahmen berechtigt. Die Einsicht in Nutzungsdateien zu anderen Zwecken ist unzulässig.</li>
        <li>Die Systembetreiber sind berechtigt und verpflichtet, Nutzerinnen und Nutzer von der weiteren Nutzung der IT-Infrastruktur, Systeme und Verfahren teilweise oder insgesamt vorübergehend und in besonders schweren Fällen dauerhaft ausschließen, wenn Tatsachen die Annahme rechtfertigen, dass diese ihren Pflichten nach § 4 nicht nachkommen. Bei nutzungsbeschränkenden Maßnahmen sind die Bestimmungen des § 3 Abs. 6 sowie die Verfahrensbestimmungen nach § 6 zu beachten.</li>
        <li>Die Systembetreiber sind zur Beachtung der gesetzlichen datenschutzrechtlichen Bestimmungen und der Datenschutz-Geschäftsordnung der FAU sowie zur Wahrung der Vertraulichkeit verpflichtet.</li>
        <li>Die Systembetreiber sind zur Beachtung der mit den Personalvertretungen geschlossenen Dienstvereinbarungen verpflichtet. Sie sind zudem verpflichtet, die Personalvertretungen in der Wahrnehmung ihrer Aufgaben nach dem BayPVG durch die Erteilung von Auskünften, die Zurverfügungstellung von Unterlagen und die Gewährung von Einsichts- und Zutrittsrechten zu unterstützen.</li>
        <li>Die Systembetreiber benennen gegenüber dem RRZE Ansprechpersonen für organisatorische und inhaltliche Absprachen in Bezug auf die Nutzung der IT-Infrastruktur, Systeme und Verfahren ihres Bereiches.</li>
        <li>Die Systembetreiber sind verpflichtet,
          <ol style="list-style-type: lower-alpha;">
            <li>neben dieser Richtlinie vom CIO-Gremium der FAU erlassene ergänzende Nutzungsbedingungen und Richtlinien zu beachten,</li>
            <li>im Verkehr mit Rechnern und Netzen anderer Betreiber deren Benutzungs- und Zugriffsrichtlinien einzuhalten,</li>
            <li>den Nutzern eigene Richtlinien in geeigneter Form zur Kenntnis zu bringen.</li>
          </ol>
        </li>
      </ol>

      <h2><a name="6"></a>§ 6&nbsp;Verfahren zur Aufdeckung missbräuchlicher Nutzung</h2>
      <ol>
        <li>Besteht aufgrund bestimmter Tatsachen der Verdacht, dass eine Nutzerin oder ein Nutzer die IT-Infrastruktur, Systeme und Verfahren nach den Bestimmungen des § 4 missbräuchlich nutzt, so ist die Nutzerin bzw. der Nutzer verpflichtet, dem Systembetreiber Auskunft über installierte Programme und benutzte Methoden zu erteilen sowie Einsicht in die Daten zu gewähren, soweit dies zur Aufklärung des Verdachts erforderlich ist.</li>
        <li>Der behördliche Datenschutzbeauftragte der FAU ist in Verdachtsfällen nach Abs. 1 durch den Systembetreiber zu informieren und kann sich nach seinem Ermessen an deren Aufklärung beteiligen. Richtet sich der Verdacht gegen Beschäftigte der FAU, ist zusätzlich in Fällen des Verdachts auf strafbare oder sonstige rechtswidrige Handlungen die Personalabteilung der Universitätsverwaltung und unter Wahrung der schutzwürdigen Belange der bzw. des Betroffenen die zuständige Personalvertretung an der Aufklärung zu beteiligen. Soweit nicht Gefahr im Verzuge besteht, hat die Beteiligung der genannten Stellen vor dem Ergreifen von tatsächlichen und/oder rechtserheblichen Maßnahmen zu erfolgen.</li>
        <li>Bei Maßnahmen zur Aufdeckung missbräuchlicher Nutzung sind die Betroffenen berechtigt, ihrerseits den behördlichen Datenschutzbeauftragten hinzuzuziehen. Beziehen sich die Maßnahmen auf Beschäftigte der FAU im Sinne des Art. 4 BayPVG, so sind diese berechtigt, neben dem behördlichen Datenschutzbeauftragten auch die örtlich zuständige Personalvertretung der FAU zu beteiligen.</li>
        <li>Verfahren nach dieser Vorschrift sind zu dokumentieren.</li>
      </ol>

      <h2><a name="7"></a>§ 7&nbsp;Haftung der Nutzerinnen und Nutzer und der FAU</h2>
      <ol>
        <li>Die Nutzerinnen und Nutzer haften nach Maßgabe der jeweiligen Haftungsbestimmungen für alle Nachteile, die der FAU dadurch entstehen, dass sie ihren Pflichten nach § 4 dieser Nutzungsrichtlinien nicht nachkommen.</li>
        <li>Die Nutzerinnen und Nutzer haften nach Maßgabe der jeweiligen Haftungsbestimmungen auch für Schäden, die durch eine unbefugte Nutzung durch Dritte entstehen, wenn sie diese Drittnutzung, z.B. durch Weitergabe der Zugangsdaten, zu vertreten haben.</li>
        <li>Die Nutzerinnen und Nutzer haben die FAU von allen Ansprüchen Dritter freizustellen, die diese auf Grund eines missbräuchlichen Verhaltens i.S.d. Abs. 2 gegen die FAU geltend machen.</li>
        <li>Die FAU übernimmt keine Gewähr dafür, dass ihre IT-Infrastruktur, Systeme und Verfahren fehlerfrei und jederzeit ohne Unterbrechung verfügbar sind. Eventuelle Datenverluste infolge technischer Störungen können nicht ausgeschlossen werden.</li>
        <li>Die FAU übernimmt keine Verantwortung für die Funktionsfähigkeit der zur Verfügung gestellten Programme. Die FAU haftet auch nicht für den Inhalt, insbesondere für die Richtigkeit, Vollständigkeit und Aktualität der Informationen, zu denen sie lediglich den Zugang zur Nutzung vermittelt.</li>
        <li>Im Übrigen haftet die FAU nur bei Vorsatz und grober Fahrlässigkeit ihrer Beschäftigten, es sei denn, dass eine schuldhafte Verletzung wesentlicher Pflichten vorliegt, deren Einhaltung für die Erreichung des dem Nutzungsverhältnis zu Grunde liegenden Zwecks von besonderer Bedeutung ist. In diesem Fall ist die Haftung der FAU auf typische, bei Begründung des Nutzungsverhältnisses vorhersehbare Schäden begrenzt.</li>
        <li>Mögliche Amtshaftungsansprüche gegen die FAU bleiben von den vorstehenden Regelungen unberührt.</li>
      </ol>

      <h2><a name="8"></a>§ 8&nbsp;Sonstige Regelungen</h2>
      <ol>
        <li>Die vorliegende Richtlinie kann durch die Systembetreiber für ihre jeweiligen Anlagen und Einrichtungen, Systeme und Verfahren durch weitergehende Regelungen ergänzt werden, sofern dadurch den Bestimmungen dieser Richtlinie nicht widersprochen wird. Das CIO-Gremium der FAU ist über weitergehende Regelungen zu informieren. Werden durch die Ergänzungen datenschutzrechtliche und/oder personalvertretungsrechtliche Belange berührt, sind diese nur unter Beteiligung und Zustimmung des behördlichen Datenschutzbeauftragten und/oder der zuständigen Personalvertretung zulässig. Zum Zeitpunkt des Inkrafttretens dieser Richtlinie bestehende und mit ihren Bestimmungen vereinbare Regelungen bestehen fort. Unvereinbare Regelungen sind außer Kraft zu setzen und durch vereinbare Regelungen zu ersetzen. Für die Änderung bestehender Regelungen sind die Verfahrensbestimmungen nach S. 2 und 3 entsprechend anzuwenden.</li>
        <li>Für die Nutzerinnen und Nutzer der IT-Infrastruktur, Systeme und Verfahren der FAU kostenpflichtige IT-Dienstleistungen sind den jeweiligen Entgeltregelungen zu entnehmen.</li>
        <li>Bei fachlichen und organisatorischen Meinungsverschiedenheiten zwischen den Nutzerinnen bzw. den Nutzern und den Systembetreibern, die sich bei der Auslegung und Anwendung dieser Richtlinie ergeben, ist eine Einigung vor dem CIO-Gremium der FAU zu suchen. Kann keine Einigung gefunden werden, entscheidet die Universitätsleitung. Die Bearbeitung von Rechtsfragen obliegt den jeweils zuständigen Stellen innerhalb der Universitätsverwaltung.</li>
        <li>Künftige Änderungen dieser Richtlinie unterliegen, soweit sie Auswirkungen auf die Nutzungs- und Arbeitsbedingungen der Beschäftigten nach Art. 4 BayPVG haben, der Mitbestimmung.</li>
        <li>Gerichtsstand für alle aus dem Nutzungsverhältnis erwachsenden rechtlichen Ansprüche ist Erlangen.</li>
      </ol>

      <h2><a name="9"></a>§ 9 Schlussbestimmungen</h2>
        <p>Diese Richtlinie tritt am Tag nach ihrer Beschlussfassung in Kraft. Sie ersetzt die Benutzungsrichtlinien für Informationsverarbeitungssysteme der Universität Erlangen-Nürnberg (Sekora-Richtlinien) vom 02.06.1995.</p>
      </div>
    </div>

    <div v-else>
      <div lang="en" class="text-justify">
      Passed by the Executive Board on 25 November 2020<p></p>

      <h2><a name="praeambel">Preamble</a></h2>
      <p>Information technology is an essential tool for research, teaching, studying and professional development, for using the library and for numerous tasks and jobs in administration and in the technical operation of Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU). This policy is intended to facilitate the use of IT facilities, systems and processes at FAU both locally and within the network securely, without hindrance and with as little disruption as possible. It governs, in particular, the rights and obligations of users and the responsibilities, rights and obligations of the system operators.</p>
      <p>&nbsp;</p>
      <p>This policy</p>
      <ul class="dashes strichpunkte">
      <li>is based on the functions of universities as defined by law and their mandate to preserve academic freedom</li>
      <li>sets forth general rules for the correct operation of IT infrastructure, systems and processes</li>
      <li>sets out the rights of third parties which must be observed (e.g. software licences, terms and conditions of network operators, data protection and privacy)</li>
      <li>stipulates rules of conduct and obliges all parties to use resources efficiently.</li>
      <li>lists possible consequences of violations of the IT acceptable use policy at FAU.</li>
      </ul>
      <h2><a name="1">Section 1 Scope</a></h2>
      <ol>
      <li>This policy applies to the use of desktop computers, mobile devices, memory, software, cable-based and wireless data networks and other technical equipment (IT infrastructure) which is used internally at FAU or externally by third parties on behalf of FAU for electronic information processing purposes and to the systems and processes operated on the basis of this infrastructure in the area of information processing for academic and administrative purposes.
      </li>
      <li>This policy shall apply across the whole of FAU for all individuals and institutions entitled to use IT at the University (Section 2 (1) and (2)) and system operators (Section 5 (1)).
      </li>
      </ol>
      <h2><a name="2">Section 2 Users (individuals and institutions), purpose</a></h2>
      <ol>
      <li>Those entitled to use the IT infrastructure, systems and processes at FAU pursuant to Section (1) are in particular
      <ol>
      <li>Members of FAU</li>
      <li>Members of institutes of higher education entitled to use the services provided by the Erlangen Regional Computing Centre (RRZE) within the regional concept of the RRZE</li>
      <li>Members of other institutes of higher education with respect to the high performance computing systems made available to these institutes of higher education</li>
      <li>Cooperation and contractual partners of FAU, to the extent necessary for the purposes of the collaboration or for meeting contractual obligations</li>
      <li>Legal entities governed by private law (official associations, foundations, cooperatives etc.) that have pledged in their statutes to support FAU and help it meet its legal obligations, according to the terms of an existing cooperation agreement</li>
      <li>Legal entities of which FAU is a shareholder</li>
      <li>Public institutions, in particular research and educational institutions, student services and authorities.</li>
      </ol>
      </li>
      <li>Other individuals or academic institutions can be granted authority to use the IT services if this benefits or is closely linked to FAU tasks and provided the ability of the principal users stated in (1) to use the services is not jeopardised in any way.
      </li>
      <li>The IT infrastructure, systems and processes at FAU are available to the authorised individuals and institutions enabling them to carry out their tasks in research, teaching, studying and professional development, for the other tasks of institutes of higher education stated in Section (2) of the Bavarian Higher Education Act and for administrative duties.</li>
      </ol>
      <h2><a name="3">Section 3&nbsp; Authorised use</a></h2>
      <ol>
      <li>Anyone who wishes to use the IT infrastructure, systems and processes at FAU requires authorisation to do so (Section 2). An application must be filed if the relevant data are not included in automated records. An exception applies to systems and services which are established for anonymous access (e.g. information services, library services, short-term guest log-in credentials for conferences).
      </li>
      <li>When granting authorisation, only those details which are directly required for making a decision on the application may be collected/used. In general, the following data may be collected: last name, first name, date of birth, gender, organisational unit to which the applicant belongs, description of the purpose of use, signature of applicant. Official photo ID or alternatively other official documents shall be required as proof that the personal details are correct. The system operators (Section 5 (1)) can also approve other suitable procedures for checking identity.</li>
      <li>The system operators shall decide whether to approve the application (Section 5 (1)). They may make authorisation dependent on certain criteria (evidence of a certain knowledge of how to use the services, only certain types of user, rules of use or transfer on the basis of foreign trade legislation) and impose certain requirements regarding use of the services.</li>
      <li>Authorisation shall be refused if:
      <ol>
      <li>The requirements pursuant to Section 2 nos. 1 to 2 are not met</li>
      <li>The intended use is outside the scope described in Section 2 (3)</li>
      <li>Sufficient reason has not been given for authorisation to be granted</li>
      <li>There are reasonable grounds to believe that the applicant will not fulfil their responsibilities as a user as set out in Section 4</li>
      <li>Sufficient resources are not available for the intended purpose based on existing capacity utilisation, the resources applied for have already been reserved for special purposes or the resources are obviously unsuitable for meeting requirements</li>
      <li>The resource requirement presents a potential risk to other systems, data networks or legally protected goods of other persons (personal data, working materials, research results etc.).</li>
      </ol>
      </li>
      <li>Authorisation may be temporarily or permanently restricted or withdrawn in the event of any subsequent action that is considered sufficient reason for refusing authorisation under paragraph 4 or if the user has failed to pay any applicable usage fees for a period longer than two months.</li>
      <li>Measures that restrict the authorisation to use IT systems defined in paragraph 5 shall be applied according to the principle of proportionality. The procedure for investigating misuse set out in Section 6 must be observed. Further proceedings under criminal, disciplinary or employment law, or de-registration due to misconduct are not precluded by the refusal of authorisation.
      </li>
      </ol>
      <h2><a name="4">Section 4 Rights and obligations of users</a></h2>
      <ol>
      <li>Users are authorised to use the IT infrastructure, systems and processes provided by FAU only for the purposes named in Section (2)(3) and only in accordance with this policy and any other terms of use or agreements that apply in individual cases. Any form of misuse of IT systems and services at FAU is prohibited. Misuse shall be defined as using IT systems and services provided by FAU for criminal or other illegal purposes, as well as:
      <ol>
      <li>Operating unauthorised hardware within the FAU network insofar as this has not been approved by the system operator responsible (Section 5 (1))</li>
      <li>Installing, operating and using systems, processes and programs which are not related to official duties</li>
      <li>Installing and using software without a valid licence</li>
      <li>Using IT facilities for private purposes if this exceeds marginal use, or poses a risk to the operation and security of the IT infrastructure, systems and processes or threatens a positive and cooperative working atmosphere. Altering network configurations for private purposes shall always be prohibited.</li>
      </ol>
      </li>
      <li>Explicit reference is made to the following forms of misuse which are punishable under criminal law (German Criminal Code, StGB):
      <ol>
      <li>Data espionage (Section 202a StGB)</li>
      <li>Phishing (Section 202b StGB)</li>
      <li>Acts preparatory to data espionage and phishing (Section 202c StGB)</li>
      <li>Handling stolen data (Section 202d StGB)</li>
      <li>Data manipulation (Section 303a StGB) and computer sabotage (Section 303b StGB)</li>
      <li>Computer fraud (Section 263a StGB)</li>
      <li>Dissemination of pornography (Section 184 StGB), in particular dissemination, procurement and possession of child pornography (Section 184b StGB) and making pornographic content available through broadcasting or telemedia services (Section 184d StGB)</li>
      <li>Dissemination of propaganda material of unconstitutional organisations (Section 86, StGB) and incitement of hatred (Section 130, StGB)</li>
      <li>Insulting or defaming others (Section 185, StGB).</li>
      </ol>
      </li>
      <li>Users are obliged to:
      <ol>
      <li>Observe copyright law (UrhG) and other legal regulations when using software, documentation and other data.</li>
      <li>Not copy or distribute software, documentation and data unless explicitly authorised, or use software, documentation and data for any unauthorised purposes, in particular for commercial use. Explicit reference is made to penalties for copyright infringement, for example by making illegal copies or software or unlawfully distributing films or music (Sections 106 et seq, UrhG).</li>
      <li>Permit regular inspection of software installed on devices provided by the University and comply with any requests to install auditing software for this purpose.</li>
      </ol>
      </li>
      <li>Users are further obliged to:
      <ol>
      <li>Work only using log-in credentials which they have been explicitly authorised to use; in particular users are prohibited from using log-in credentials belonging to other users. Sharing log-in credentials with other users (for example username and password) or storing credentials in systems and devices that could be used by a third-party is prohibited (function accounts which are explicitly authorised for use by multiple users for a specific purpose are excluded from this rule; the account owner is required to maintain a record of authorised users).</li>
      <li>Protect the IT infrastructure, systems and processes at FAU from unauthorised access by using a password which the user is obliged to keep secret, or a similar or superior method of authentication.</li>
      <li>Take precautionary measures to prevent third parties from gaining unauthorised access to data; in particular by using a password that meets current technical requirements and logging out properly from any systems or processes or taking measures to secure their device such as locking the screen while they are absent from their workspace.</li>
      <li>Immediately report any incidents that come to their attention which may be relevant to IT security to the IT officer responsible and inform the RRZE by e-mail at <a href="mailto:abuse@fau.de">abuse@fau.de</a>.</li>
      </ol>
      </li>
      <li>Users are responsible for all actions that are performed using their log-in credentials and may also be held liable for actions performed by a third party if it can be proven that the account owner has acted negligently in allowing unauthorised access using their log-in credentials. In such cases, FAU is entitled to claim reimbursement of usage fees from a user which would have been incurred if the third party had been authorised to use the account.
      </li>
      <li>Users are obliged to use any IT equipment and resources provided by FAU (workstations, CPU capacity, storage, bandwidth, peripheral devices and consumables) in a responsible and economical way. Users are further obliged to avoid causing any disruption to IT operations insofar as foreseeable, and avoid to the best of their knowledge any activities that may cause damage or disruption to other users or to the IT infrastructure, systems and processes at FAU.
      </li>
      <li>Users may not take any of the following actions without authorisation from the system operator responsible (Section 5 (1)):
      <ol>
      <li>Modify hardware configurations or use available interfaces to alter network or system infrastructure (for example by connecting proxy servers, switches and access points).</li>
      <li>Change the configuration of systems (operating systems, data networks or similar) or processes.</li>
      </ol>
      </li>
      <li>Users must observe data protection and privacy regulations and the data protection regulations of FAU in all activities which involve the processing of personal data. This also applies if personal data is processed using IT services provided by third parties (for example cloud services).</li>
      <li>In the event of an investigation of misuse or service disruption caused by improper use, the rights of the system operator (Section 5 (1)) and the procedure set out in Section 6 must be observed immediately by users.
      </li>
      <li>Users are obliged to:
      <ol>
      <li>Observe all other terms of use and agreements issued by the FAU CIO committee or the system operators in addition to this acceptable use policy.</li>
      <li>Comply with terms of use and access policies of other operators when using devices and networks belonging to them.</li>
      </ol>
      </li>
      </ol>
      <h2><a name="5">Section 5 Rights and obligations of system operators</a></h2>
      <ol>
      <li>In addition to the RRZE, system operators are defined as all organisational units of FAU (faculties, schools, departments, chairs, institutes, central institutions, units and other divisions) that operate or provide IT infrastructure, systems and processes as set out in Section 1 (1) independently or with the support of internal or external service providers. If several FAU organisational units are responsible for the administrative, technical or content-related aspects of a system, they must designate a single system operator as responsible for the system within the meaning of this policy. Managers are responsible for ensuring the proper operation of IT systems as set out in this policy within their FAU organisational unit. Although operational tasks may be delegated to technical staff (administrators), managers remain ultimately responsible for the proper operation of IT systems.
      </li>
      <li>System operators must use the IT infrastructure, systems and processes provided at FAU exclusively in carrying out their tasks. In particular, this means that additional systems and processes may not be introduced, if an equivalent service which could be used as an alternative is already provided by FAU. This also applies if a usage fee applies for using or sharing the service. The CIO committee will decide in any case of doubt and may grant exceptions.
      </li>
      <li>The system operators are authorised and obliged to record and maintain appropriate proof of authorisation. The documents and information that are created or collected when applying for or extending authorisation, as well as any usage data that may arise, may be stored automatically and must be deleted when the authorisation expires. This does not apply to data to which certain retention obligations apply (e.g. accounting data).
      </li>
      <li>The system operator is authorised to document and monitor the utilisation of IT infrastructure, systems and processes by individual users but only to the extent that this is necessary:
      <ol>
      <li>To ensure the proper operation of IT systems and services</li>
      <li>For resource planning and system administration</li>
      <li>To protect the personal data of other users</li>
      <li>For billing purposes</li>
      <li>To detect and resolve service disruptions</li>
      <li>To investigate and prevent misuse or illegal activities</li>
      </ol>
      </li>
      <li>The system operators shall contribute in an appropriate manner, in particular in the form of regular spot checks, to prevent, resolve, or investigate misuse. System operators are authorised to check passwords and usage data and take preventative action, for example changing passwords that are easy to guess, to protect the IT infrastructure, systems, processes and usage data from unauthorised access by third parties. Users must be directly informed of any required changes to their password or any other security measures that affect their usage of IT systems and services.</li>
      <li>System operators are authorised to inspect usage data in compliance with data protection and privacy regulations and take preventative measures insofar as this is necessary to resolve service disruption or to investigate and prevent misuse. Inspecting usage data for any other purpose is not permitted.</li>
      <li>System operators are authorised and obliged to temporarily exclude users from using the IT infrastructure, systems and processes either in part or entirely and may ban users permanently in extreme cases if it can be assumed that the user will not fulfil their responsibilities as set out in Section 4. If measures that restrict use of IT systems and services are taken, the provisions set forth in Section 3 (6) and the procedural provisions in Section 6 must be observed.</li>
      <li>System operators are obliged to observe data protection and privacy regulations and the data protection regulations of FAU and to maintain confidentiality.</li>
      <li>System operators must observe agreements concluded with HR representatives. They are also obliged to support staff councils in the performance of their duties under the Bavarian Employee Representation Act (BayPVG) by providing information, making documents available and granting rights of inspection and access.
      </li>
      <li>System operators must appoint RRZE contact persons who are responsible for administrative and content-related agreements relating to the use of IT infrastructure, systems and processes in their specific capacity.</li>
      <li>System operators are obliged to:
      <ol>
      <li>Observe all other terms of use and agreements issued by the FAU CIO committee in addition to this policy.</li>
      <li>Comply with terms of use and access policies of other operators when using devices and networks belonging to FAU.</li>
      <li>Bring their own usage guidelines to the attention of users in a suitable form.</li>
      </ol>
      </li>
      </ol>
      <h2><a name="6">Section 6 Procedure for investigating misuse</a></h2>
      <ol>
      <li>If there are sufficient grounds for suspecting misuse of IT infrastructure, systems and processes as defined in Section 4, the user is obliged to provide the system operator with information about installed programs and methods used and to grant access to data, insofar as this is necessary to clarify the suspicion.
      </li>
      <li>The FAU data protection officer must be informed by the system operator in the event of suspected misuse pursuant to paragraph 1 and can participate in the investigation at his or her discretion. If an FAU employee is suspected of misuse, Human Resources must also be involved in the event of suspicion of criminal or other unlawful actions and, while safeguarding the legitimate interests of the person concerned, the staff council responsible must be involved in the investigation. Insofar as there are no exigent circumstances, the aforementioned authorities must be involved before actual and/or legally relevant measures are taken.
      </li>
      <li>Users who are affected by measures investigating misuse may involve their data protection officer. Users under investigation for misuse may request involvement of the official data protection officer. If the measures refer to employees of FAU as defined in Section 4 BayPVG, the affected employee is entitled to involve not only the official data protection officer but also a representative of their staff council.
      </li>
      <li>Investigations according to this procedure must be documented.
      </li>
      </ol>
      <h2><a name="7">Section 7 Liability of users and FAU</a></h2>
      <ol>
      <li>Users shall be liable in accordance with the respective liability provisions for all damages incurred by FAU as a result of their failure to comply with their obligations under Section 4 of this policy.
      </li>
      <li>In accordance with the respective liability provisions, the users are also liable for damages resulting from unauthorised use by third parties if they are responsible for this third-party use due to misconduct, for example by forwarding log-in credentials.
      </li>
      <li>The users shall indemnify FAU against all claims asserted by third parties on the basis of misconduct as defined in paragraph 2.
      </li>
      <li>FAU does not warrant that its IT infrastructure, systems and processes are free from errors and available at all times without interruption. Possible loss of data due to technical faults cannot be excluded.
      </li>
      <li>FAU assumes no responsibility for the functionality of the programs provided. FAU is also not liable for the content, in particular for the accuracy, completeness and validity of the information to which it merely provides access.
      </li>
      <li>In all other respects, FAU shall only be held liable in the event of intent and gross negligence on the part of its employees, unless there is a culpable breach of material obligations, the observance of which is of particular importance for achieving the purpose of the usage agreement. In this case, FAU’s liability shall be limited to typical damages which were foreseeable at the time of the establishment of the usage agreement.
      </li>
      <li>Possible public liability claims against FAU shall remain unaffected by the above provisions.
      </li>
      </ol>
      <h2><a name="8">Section 8 Other provisions</a></h2>
      <ol>
      <li>This policy may be supplemented by the system operators with further provisions appropriate for their IT infrastructure, systems and processes, provided that these do not conflict with the provisions of this policy. The FAU CIO committee must be informed about more extensive regulations. If data protection and/or employee representation issues are affected by the amendments, these are only permissible with the participation and consent of the official data protection officer and/or the staff council responsible. Regulations that exist on the date that this policy enters into force and that are compatible with its provisions shall continue to apply. Incompatible regulations will be replaced with compatible regulations and invalid regulations shall no longer apply. For the amendment of existing regulations, the procedural provisions according to sentences 2 and 3 above shall apply accordingly.
      </li>
      <li>Usage fees for IT infrastructure, systems and processes are available in the respective fee regulations.
      </li>
      <li>In the event of technical and organisational differences of opinion between users and system operators arising from the interpretation and application of this policy, an agreement shall be sought from the FAU CIO committee. If no agreement can be found, the Executive Board will reach a decision. Matters relating to legal affairs will be managed by the appropriate office in university administration.
      </li>
      <li>Future amendments to this policy shall be subject to consultation to the extent that they have an impact on the usage and working conditions of employees pursuant to Section 4 BayPVG.
      </li>
      <li>The place of jurisdiction for all legal claims arising from the user agreement is Erlangen.
      </li>
      </ol>
      <h2><a name="9">Section 9 Final provisions</a></h2>
      <p>This policy shall enter into force on the day following its publication. It replaces the Usage Guidelines for Information Processing Systems of the University of Erlangen-Nürnberg (SEKORA regulations) of 2 June 1995.</p>
      <p>&nbsp;
      </p></div>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Touconsent',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {},
  data () {
    return {}
  },
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  },
  watch: {},
  created () {
  },
  methods: {}
}
</script>
