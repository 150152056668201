import Vue from 'vue'
import _ from 'lodash'
import { projectServiceForManagerView } from '@/services/project.service'
import { requestServiceForManagerView } from '@/services/request.service'
import { accountServiceForManagerView } from '@/services/account.service'
import { commentServiceForManagerView } from '@/services/comment.service'
import { invitationServiceForManagerView } from '@/services/invitation.service'
import { voucherServiceForManagerView } from '@/services/voucher.service'
import { usageServiceForManagerView } from '@/services/usage.service'

const state = () => ({
  projects: {},
  invitationsWithDisabledResend: []
})

const getters = {
  requestsPendingForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.requests.filter(request => request.state === 'pending')
    } else {
      return {}
    }
  },
  requestsPendingWithFollowUpForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.requests.filter(request => request.state === 'pending-follow-up')
    } else {
      return {}
    }
  },
  requestsArchivedForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.requests.filter(request => request.state !== 'pending' && request.state !== 'pending-follow-up')
    } else {
      return {}
    }
  },
  invitationsPendingForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.invitations.filter(invitation => invitation.state === 'pending')
    } else {
      return {}
    }
  },
  invitationsArchivedForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.invitations.filter(invitation => invitation.state !== 'pending')
    } else {
      return {}
    }
  },
  vouchersPendingForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.vouchers.filter(voucher => voucher.state === 'pending')
    } else {
      return {}
    }
  },
  vouchersArchivedForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.vouchers.filter(voucher => voucher.state !== 'pending')
    } else {
      return {}
    }
  },
  latestVoucherCodesForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.latestVoucherCodes
    } else {
      return []
    }
  },
  accountsActiveForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.accounts.filter(account => account.state === 'active')
    } else {
      return {}
    }
  },
  accountsArchivedForProject: (state) => (projectId) => {
    const project = state.projects.find(project => project.projectId === projectId)
    if (project) {
      return project.accounts.filter(account => account.state !== 'active')
    } else {
      return {}
    }
  },
  isResendDisabledForInvitation: (state) => (invitationId) => {
    const invitation = state.invitationsWithDisabledResend.find(invitation => invitation.invitationId === invitationId)
    if (invitation) {
      return true
    } else {
      return false
    }
  }
}

const actions = {
  getProjects ({ commit }, payload) {
    const params = { filter: payload?.filter ? payload.filter : '' }
    return projectServiceForManagerView.list(params).then((projects) => {
      _.forEach(projects.items, (project) => {
        project.requests = []
        project.accounts = []
        project.invitations = []
        project.vouchers = []
        project.latestVoucherCodes = []
        project.loadMe = false
      })
      commit({
        type: 'setProjects',
        projects: projects.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  getRequestsForProject ({ commit }, payload) {
    const params = { projectId: payload.projectId }
    requestServiceForManagerView.listForProject(params).then((requests) => {
      commit({
        type: 'setRequestsForProject',
        projectId: payload.projectId,
        requests: requests.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  getInvitationsForProject ({ commit }, payload) {
    const params = { projectId: payload.projectId, filter: payload?.filter ? payload.filter : '' }
    invitationServiceForManagerView.listForProject(params).then((invitations) => {
      commit({
        type: 'setInvitationsForProject',
        projectId: payload.projectId,
        invitations: invitations.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  getVouchersForProject ({ commit }, payload) {
    const params = { projectId: payload.projectId, filter: payload?.filter ? payload.filter : '' }
    voucherServiceForManagerView.listForProject(params).then((vouchers) => {
      commit({
        type: 'setVouchersForProject',
        projectId: payload.projectId,
        vouchers: vouchers.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  addInvitation ({ commit }, payload) {
    const params = { createPolicy: payload.createPolicy }
    return invitationServiceForManagerView.create(payload.invitation, params).then(
      response => {
        if (response.reactivated) {
          // Reload all accounts instead of retroactively handling project state if reactivations returned
          this.dispatch({ type: 'manager/getAccountsForProject', projectId: payload.invitation.forProject.key })
        } else {
          // For single invitation: Keep addInvitation commit:
          // Only special case "renew pending invitation" will show up as soft display bug (fixed by reload in frontend)
          commit({ type: 'addInvitation', invitation: response })
        }
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  addVoucher ({ commit }, payload) {
    return voucherServiceForManagerView.create(payload.voucher).then(
      response => {
        commit({ type: 'addVoucher', voucher: response })
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  addBulkInvitation ({ commit }, payload) {
    const params = { sendBulk: true, createPolicy: payload.createPolicy }
    return invitationServiceForManagerView.create(payload.invitation, params).then(
      response => {
        if (response.invitations) {
          /* Was:
               * commit({ type: 'addInvitations', invitations: response.invitations })
             Change to complete reload of project invitations:
               * Slight overhead if bulk invites are all new
               * But as soon as one invitation is superseded: 'addInvitations' would add new invites to (state unchanged) old invites as pending
               * Therefore would require retroactive cleanup of indexed, specific invitations currently saved in project state: Bulky -> Just reload it all :)
          */
          this.dispatch({ type: 'manager/getInvitationsForProject', projectId: payload.invitation.forProject.key })
        }
        if (response.reactivations) {
          // Reload all accounts instead of retroactively handling project state if reactivations returned
          this.dispatch({ type: 'manager/getAccountsForProject', projectId: payload.invitation.forProject.key })
        }
        return response
      }
    ).catch(error => {
      if (error.status === 500) {
        var parsed = JSON.parse(error.request.response)
        if (parsed.payloads.invitations.length !== 0) {
          console.log('Error with non-empty invitation response array: Reload invites')
          // commit({ type: 'addInvitations', invitations: parsed.payloads.invitations })
          this.dispatch({ type: 'manager/getInvitationsForProject', projectId: payload.invitation.forProject.key })
        }
        if (parsed.payloads.reacivations.length !== 0) {
          console.log('Error with non-empty reactivation response array: Reload accounts')
          this.dispatch({ type: 'manager/getAccountsForProject', projectId: payload.invitation.forProject.key })
        }
      }
      throw error
    })
  },
  addBulkVoucher ({ commit }, payload) {
    const params = { generateBulk: true }
    return voucherServiceForManagerView.create(payload.voucher, params).then(
      response => {
        commit({ type: 'addVouchers', vouchers: response })
        commit({ type: 'addLatestVoucherCodes', vouchers: response })
        return response
      }
    ).catch(error => {
      if (error.status === 500) {
        var parsed = JSON.parse(error.request.response)
        if (parsed.vouchers.length !== 0) {
          console.log('Error with non-empty voucher response array: Add to store')
          commit({ type: 'addVouchers', vouchers: parsed.vouchers })
          commit({ type: 'addLatestVoucherCodes', vouchers: parsed.vouchers })
        }
      }
      throw error
    })
  },
  getAccountsForProject ({ commit }, payload) {
    const params = { projectId: payload.projectId, filter: payload?.filter ? payload.filter : '', sortBy: payload?.sortBy ? payload.sortBy : '', sortDesc: payload?.sortDesc }
    accountServiceForManagerView.listForProject(params).then((accounts) => {
      commit({
        type: 'setAccountsForProject',
        projectId: payload.projectId,
        accounts: accounts.items
      })
    }).catch(error => {
      console.error(error)
    })
  },
  updateRequest ({ commit }, payload) {
    return requestServiceForManagerView.update(payload.request).then(
      response => {
        commit({ type: 'setRequest', request: response })
        return response
      }
    ).catch(error => {
      return error
    })
  },
  addAccount ({ commit }, payload) {
    return accountServiceForManagerView.create(payload.account).then(
      response => {
        commit({ type: 'addAccount', account: response })
        return response
      }
    ).catch(error => {
      return error
    })
  },
  updateAccount ({ commit }, payload) {
    return accountServiceForManagerView.update(payload.account).then(
      response => {
        commit({ type: 'setAccount', account: response })
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  resendMail ({ commit }, payload) {
    return invitationServiceForManagerView.resend(payload.invitationId).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  disableResendForInvitation ({ commit }, payload) {
    commit({ type: 'setInvitationWithDisabledResend', invitationId: payload.invitationId })
  },
  getProjectUsages ({ commit }, payload) {
    return usageServiceForManagerView.getCurrentForProject(payload.targetId).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  getAccountUsages ({ commit }, payload) {
    return usageServiceForManagerView.getCurrentForAccount(payload.targetId, { forProject: payload.forProject }).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  addComment ({ commit }, payload) {
    return commentServiceForManagerView.create({ targetId: payload.targetId, comment: payload.comment, changes: payload.changes }).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  getValidity ({ commit }, payload) {
    return projectServiceForManagerView.getValidity(payload.projectId).then(
      response => {
        return response
      }
    ).catch(error => {
      throw error
    })
  },
  deleteInvitation ({ commit }, payload) {
    return invitationServiceForManagerView.delete(payload.id).then(
      response => {
        this.dispatch({ type: 'manager/getInvitationsForProject', projectId: payload.project })
        return response
      }
    ).catch(error => {
      throw error
    })
  }
}

const mutations = {
  setProjects (state, payload) {
    state.projects = payload.projects
  },
  setInvitationWithDisabledResend (state, payload) {
    state.invitationsWithDisabledResend.push({ invitationId: payload.invitationId })
    return true
  },
  setRequestsForProject (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.projectId) {
        state.projects[projectIndex].requests = payload.requests
        return true
      }
    })
  },
  setInvitationsForProject (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.projectId) {
        state.projects[projectIndex].invitations = payload.invitations
        return true
      }
    })
  },
  setVouchersForProject (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.projectId) {
        state.projects[projectIndex].vouchers = payload.vouchers
        return true
      }
    })
  },
  setAccountsForProject (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.projectId) {
        state.projects[projectIndex].accounts = payload.accounts
        return true
      }
    })
  },
  setRequest (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.request.forProject.key) {
        state.projects[projectIndex].requests.find((request, requestIndex) => {
          if (request.requestId === payload.request.requestId) {
            Vue.set(state.projects[projectIndex].requests, requestIndex, payload.request)
          }
        })
      }
    })
  },
  addAccount (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.account.project.key) {
        state.projects[projectIndex].accounts.push(payload.account)
      }
    })
  },
  setAccount (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.account.project.key) {
        state.projects[projectIndex].accounts.find((account, accountIndex) => {
          if (account.username === payload.account.username) {
            Vue.set(state.projects[projectIndex].accounts, accountIndex, payload.account)
          }
        })
      }
    })
  },
  addInvitation (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.invitation.forProject.key) {
        payload.invitation.loadInfo = false
        state.projects[projectIndex].invitations.push(payload.invitation)
      }
    })
  },
  addInvitations (state, payload) {
    for (var index in payload.invitations) {
      state.projects.find((project, projectIndex) => {
        if (project.projectId === payload.invitations[index].forProject.key) {
          state.projects[projectIndex].invitations.push(payload.invitations[index])
        }
      })
    }
  },
  addVoucher (state, payload) {
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.voucher.forProject.key) {
        state.projects[projectIndex].vouchers.push(payload.voucher)
      }
    })
  },
  addVouchers (state, payload) {
    for (var index in payload.vouchers) {
      state.projects.find((project, projectIndex) => {
        if (project.projectId === payload.vouchers[index].forProject.key) {
          state.projects[projectIndex].vouchers.push(payload.vouchers[index])
        }
      })
    }
  },
  addLatestVoucherCodes (state, payload) {
    let pindex = 0
    // Find project and empty list
    state.projects.find((project, projectIndex) => {
      if (project.projectId === payload.vouchers[0].forProject.key) {
        state.projects[projectIndex].latestVoucherCodes = []
        pindex = projectIndex
      }
    })
    // Add
    for (var index in payload.vouchers) {
      state.projects[pindex].latestVoucherCodes.push(payload.vouchers[index].forCode)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
