import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import _ from 'lodash'
import LocaleMessageService from '@/services/localeMessage.service'

Vue.use(VueI18n)

const API_URL = process.env.VUE_APP_API_URL
const REPORT_MISSINGS = process.env.NODE_ENV === 'development'
const NO_REPORT_MISSINGS = false

const MISSINGS = {}

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  missing: (locale, key, vm, values) => {
    // handle translation missing
    if (REPORT_MISSINGS && !NO_REPORT_MISSINGS) {
      const mkey = locale + ':' + key
      if (!(mkey in MISSINGS)) {
        LocaleMessageService.missing({ key: key, locale: locale, values: values }).then(() => {
        })
        MISSINGS[mkey] = true
      }
    }

    return key
  }
})

export default i18n

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  // If the language was already loaded
  if (!_.isEmpty(i18n.getLocaleMessage(lang))) {
    // If the same language
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }
    // console.log("change locale to already loaded one")
    return Promise.resolve(setI18nLanguage(lang))
  }

  // console.log("get main messages for locale " + lang)
  return axios.get(API_URL + 'lang/' + lang).then(response => {
    const messages = response.data
    i18n.setLocaleMessage(lang, messages.default)
    return setI18nLanguage(lang)
  })
}

export function loadComponentLanguageAsync (component) {
  const lang = component.$i18n.locale
  if (!_.isEmpty(component.$i18n.getLocaleMessage(lang))) {
    return Promise.resolve(lang)
  }
  return axios.get(API_URL + 'lang/' + lang, { params: { comp: component.$options.name } }).then(response => {
    const messages = response.data
    component.$i18n.setLocaleMessage(lang, messages.default)
  })
}

export function messagesAvailable (component) {
  if ('$i18n' in component) {
    return Object.keys(component.$i18n.getLocaleMessage(component.$i18n.locale)).length !== 0
  } else {
    return false
  }
}

export function parentMessagesAvailable (component) {
  if ('$i18n' in component.$parent) {
    return Object.keys(component.$parent.$i18n.getLocaleMessage(component.$parent.$i18n.locale)).length !== 0
  } else {
    return false
  }
}
