<template>
  <div id="app">
    <template v-if="!popup">
      <template v-show="messagesAvailable">
        <b-navbar id="nav-top" toggleable="lg" type="dark" variant="primary" sticky class="mb-3">
          <b-navbar-brand class="mr-2 my-0" to="/">
            <b-img src="./assets/images/nhr_logo.png" style="background-color: #eaecee;" class="p-1 mr-1" rounded
                   height="40px" alt="NHR"/>
            {{ $t('app.navigation.title') }}
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <!-- Primary Vue view routing with Vue Bootstrap -->
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item to="/">{{ $t('home.title') }}</b-nav-item>
              <b-nav-item v-if="hasRoleUser && hasCorrectAttributes" to="/user">{{ $t('user.title') }}</b-nav-item>
              <b-nav-item v-if="hasRoleManager && hasCorrectAttributes" to="/management">{{ $t('management.title') }}</b-nav-item>
              <b-nav-item v-if="hasRoleAdvisor && hasCorrectAttributes" to="/advisor">{{ $t('advisor.title') }}</b-nav-item>
              <b-nav-item v-if="hasRoleSupport && hasCorrectAttributes" to="/support">{{ $t('support.title') }}</b-nav-item>
              <b-nav-item v-if="hasRoleAdmin && hasCorrectAttributes" to="/admin">{{ $t('admin.title') }}</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <b-nav-item class="align-self-center" @click="ping">
                <b-icon-cloud shift-v="-1"/> Ping
              </b-nav-item>
              <b-nav-item class="align-self-center" v-if="hasRoleSuperAdmin && hasCorrectAttributes" :href="backendURL" target="_blank" rel="noopener">
                <b-icon-gear shift-v="-1"/> {{ $t('backend.label') }}
              </b-nav-item>

              <b-nav-item-dropdown class="align-self-center" right>
                <template v-slot:button-content>
                  <b-icon-flag shift-v="-1"/> {{ $t('language.text', {language: $root.$i18n.locale}) }}
                </template>
                <b-dropdown-item v-for="language in languages" :key="language" @click="changeLocale(language)">
                  {{ $t(`language.${language}.label`) }}
                  <b-icon-check shift-v="-1" v-if="language === currentLanguage"/>
                </b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown v-if="currentUser" class="align-self-center" right no-caret>
                <template v-slot:button-content>
                  <b-button variant="outline-light" class="py-1 px-2">
                    <b-icon-person-circle shift-v="-1" class="mr-1"/> <em>{{ currentUser.username }}</em>
                  </b-button>
                </template>
                <b-dropdown-item v-if="hasCorrectAttributes" to="/profile"><b-icon-person-circle/> {{ $t('profile.title') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout"><b-icon-box-arrow-right/> {{ $t('logout.label') }}</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item class="align-self-center" v-else v-b-modal.login-modal>
                  <b-button variant="outline-light" class="py-1 px-2">
                    <b-icon-box-arrow-in-right shift-v="-1" class="mr-2"/>Login
                  </b-button>
              </b-nav-item>

            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <!-- Vue View Router Tag -->
        <router-view/>

        <b-modal id="fhpc-consent-modal" :title="$t('consent.title')" no-close-on-backdrop no-close-on-esc hide-header-close
                 size="xl">
          <template #modal-header="{}">
            <h2>{{ $t('consent.title') }}</h2>
            <b-button-group class="mx-2">
              <b-button v-for="language in languages" :key="language" @click="changeLocale(language)"
                        :pressed="language === currentLanguage" size="sm" variant="outline-secondary">
                {{ language }}
              </b-button>
            </b-button-group>
          </template>
          <template #modal-footer="{}">
            <b-button variant="danger" @click="refuseConsent">
              <b-icon-x/> {{ $t('consent.refuse.label') }}
            </b-button>
            <b-button variant="success" @click="giveConsent">
              <b-icon-check/> {{ $t('consent.give.label') }}
            </b-button>
          </template>

          <Consent/>
        </b-modal>

        <b-modal id="fhpc-touconsent-modal" :title="$t('touconsent.title')" no-close-on-backdrop no-close-on-esc hide-header-close
                 size="xl">
          <template #modal-header="{}">
            <h2>{{ $t('touconsent.title') }}</h2>
            <b-button-group class="mx-2">
              <b-button v-for="language in languages" :key="language" @click="changeLocale(language)"
                        :pressed="language === currentLanguage" size="sm" variant="outline-secondary">
                {{ language }}
              </b-button>
            </b-button-group>
          </template>
          <template #modal-footer="{}">
            <b-button variant="danger" @click="refuseTouconsent">
              <b-icon-x/> {{ $t('consent.refuse.label') }}
            </b-button>
            <b-button variant="success" @click="giveTouconsent">
              <b-icon-check/> {{ $t('consent.give.label') }}
            </b-button>
          </template>

          <Touconsent/>
        </b-modal>

        <b-modal id="login-modal" :title="$t('login.title')" size="lg" @ok="handleOk" :ok-title="$t('ok.label')"
                 :cancel-title="$t('cancel.label')" :cancel-disabled="true" :ok-disabled="true" :hide-footer="true">
          <Login/>
        </b-modal>
      </template>
      <b-navbar id="nav-bottom" fixed="bottom" variant="primary" type="dark">
        <b-navbar-nav small>
          <b-nav-item to="/contact">{{ $t('contact.label') }}</b-nav-item>
          <b-nav-item to="/imprint">{{ $t('imprint.label') }}</b-nav-item>
          <b-nav-item to="/privacy">{{ $t('privacy.label') }}</b-nav-item>
          <b-nav-item to="/accessibility">{{ $t('accessibility.label') }}</b-nav-item>
          <b-nav-item to="/tou">{{ $t('tou.label') }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" small>
          <b-navbar-brand class="mx-3" href="https://www.rrze.fau.de" target="_blank" rel="noopener">
            <b-img src="./assets/images/rrze_logo.png" height="30px" alt="RRZE"/>
          </b-navbar-brand>
          <b-navbar-brand class="mx-3" href="https://www.fau.de" target="_blank" rel="noopener">
            <b-img src="./assets/images/fau_logo.png" height="25px" alt="FAU"/>
          </b-navbar-brand>
        </b-navbar-nav>
      </b-navbar>
    </template>
  </div>
</template>

<script>
import Login from '@/components/generic/Login.vue'
import AuthService from '@/services/auth.service'
import { loadLanguageAsync, messagesAvailable } from '@/i18n'
import hasRoleMixin from '@/mixins/hasRole.mixin'
import store from './store'
import Consent from '@/components/generic/Consent'
import Touconsent from '@/components/generic/Touconsent'
import ConsentService from '@/services/consent.service'
import TouconsentService from '@/services/touconsent.service'

const onOffListener = () => {
  store.dispatch('auth/ping')
}

const preventNav = (event) => {
  if (store.state.pendingChanges.length < 1) {
    return
  }
  event.preventDefault()
  // Chrome requires returnValue to be set.
  event.returnValue = ''
}

export default {
  name: 'App',
  components: {
    Login,
    Consent,
    Touconsent
  },
  mixins: [hasRoleMixin],
  store,
  data: function () {
    return {
      popup: false,
      languages: ['en', 'de'],
      backendURL: process.env.VUE_APP_BACKEND_UI_URL
    }
  },
  computed: {
    offline () {
      return !this.$store.state.auth.status.clientOnline
    },
    serverUnavailable () {
      return !this.$store.state.auth.status.serverOnline
    },
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    },
    hasCorrectAttributes () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.hasCorrectAttributes
      } else {
        return null
      }
    },
    currentUser () {
      return this.$store.state.auth.user
    },
    unauthorizedModal () {
      return this.$store.state.auth.unauthorized.modal
    },
    hasGivenConsent () {
      return this.currentUser ? this.currentUser.hasGivenConsent : null
    },
    hasGivenTouconsent () {
      return this.currentUser ? this.currentUser.hasGivenTouconsent : null
    },
    currentLanguage () {
      return this.$root.$i18n.locale
    }
  },
  watch: {
    messagesAvailable () {
      return messagesAvailable(this)
    },
    unauthorizedModal: function (newValue) {
      if (newValue === true) {
        // this.showMsgBoxLogin()
        this.$bvModal.show('login-modal')
      }
    },
    hasGivenConsent: function (newValue) {
      if (newValue === false) {
        this.$bvModal.show('fhpc-consent-modal')
      } else {
        this.$bvModal.hide('fhpc-consent-modal')
      }
    },
    hasGivenTouconsent: function (newValue) {
      if (newValue === false) {
        this.$bvModal.show('fhpc-touconsent-modal')
      } else {
        this.$bvModal.hide('fhpc-touconsent-modal')
      }
    },
    loggedIn: function (newValue) {
      if (newValue === true) {
        this.$bvModal.hide('login-modal')
      }
    }
  },
  created () {
    if (window.opener) {
      this.popup = true
      window.opener.postMessage('fhpc-popup')
      window.close()
    } else {
      window.addEventListener('online', onOffListener)
      window.addEventListener('offline', onOffListener)
      window.addEventListener('beforeunload', preventNav)
      this.$store.dispatch('auth/ping')

      const language = navigator.language.substr(0, 2).toLowerCase()
      this.changeLocale(language)
    }
  },
  destroyed () {
    window.removeEventListener('online', onOffListener)
    window.removeEventListener('offline', onOffListener)
    window.removeEventListener('beforeunload', preventNav)
  },
  methods: {
    changeLocale (language) {
      loadLanguageAsync(language)
    },
    ping () {
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(
          this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }),
          this.$i18n.t('result.success.title'),
          'success'
        )
      ).catch(
        error => this.makeToast(
          this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }),
          this.$i18n.t('result.error.title'),
          'danger'
        )
      )
    },
    giveConsent () {
      ConsentService.give().then(() =>
        this.$store.dispatch('auth/ping')
      )
    },
    refuseConsent () {
      this.logout()
    },
    giveTouconsent () {
      TouconsentService.give().then(() =>
        this.$store.dispatch('auth/ping')
      )
    },
    refuseTouconsent () {
      this.logout()
    },
    login () {
      AuthService.samlLogin()
    },
    logout () {
      AuthService.logout()
    },
    handleOk () {
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
  padding-bottom: 50px;
}

#nav-bottom {
  padding: 5px;
}

#nav-top a.router-link-exact-active,
#nav-bottom a.router-link-exact-active {
  color: #fff;
  font-weight: bold;
}

#nav-top a.dropdown-item.router-link-exact-active,
#nav-bottom a.dropdown-item.router-link-exact-active {
  color: #000;
}

.navbar-brand {
  font-weight: normal !important;
}

#b-toaster-top-right {
  margin-top: 65px;
}

.cursor-help{
  cursor: help;
}
</style>
