import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'vouchers'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class VoucherService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  listForProject (params = {}) {
    params.view = this.view
    params.type = 'listForProject'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  redeem (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default VoucherService
export const voucherServiceForUserView = new VoucherService(VIEW_USER)
export const voucherServiceForManagerView = new VoucherService(VIEW_MANAGER)
export const voucherServiceForAdvisorView = new VoucherService(VIEW_ADVISOR)
export const voucherServiceForSupportView = new VoucherService(VIEW_SUPPORT)
export const voucherServiceForAdminView = new VoucherService(VIEW_ADMIN)
