import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'requests'

const VIEW_ADMIN = 'admin'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class RequestService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  listForProject (params = {}) {
    params.view = this.view
    params.type = 'listForProject'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default RequestService
export const requestServiceForUserView = new RequestService(VIEW_USER)
export const requestServiceForManagerView = new RequestService(VIEW_MANAGER)
export const requestServiceForAdminView = new RequestService(VIEW_ADMIN)
