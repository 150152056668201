import axios from 'axios'
import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'localeMessages'
const ENDPOINT_COUNT = 'localeMessagesCount'
const ENDPOINT_MISSING = 'localeMessageMissing'

class LocaleMessageService {
  list (params) {
    return DefaultApiService.list(ENDPOINT, params)
  }

  create (data, params) {
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params) {
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params) {
    return DefaultApiService.delete(ENDPOINT, id, params)
  }

  count (params) {
    return DefaultApiService.count(ENDPOINT_COUNT, params)
  }

  missing (data, params) {
    return axios.put(DefaultApiService.API_URL + ENDPOINT_MISSING, data, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => DefaultApiService.errorHandler(error)
    )
  }
}

export default new LocaleMessageService()
