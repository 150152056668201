export default {
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    hasRoleUser () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_USER')
      }
      return false
    },
    hasRoleManager () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MANAGER')
      }
      return false
    },
    hasRoleAdvisor () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADVISOR')
      }
      return false
    },
    hasRoleSupport () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_SUPPORT')
      }
      return false
    },
    hasRoleAdmin () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN')
      }
      return false
    },
    hasRoleSuperAdmin () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_SUPERADMIN')
      }
      return false
    }
  }
}
