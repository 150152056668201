import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import(/* webpackChunkName: "management" */ '../views/Management.vue')
  },
  {
    path: '/advisor',
    name: 'Advisor',
    component: () => import(/* webpackChunkName: "support" */ '../views/Advisor.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/footer/Contact.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/footer/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/footer/Privacy.vue')
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: () => import(/* webpackChunkName: "accessibility" */ '../views/footer/Accessibility.vue')
  },
  {
    path: '/tou',
    name: 'Tou',
    component: () => import(/* webpackChunkName: "tou" */ '../views/footer/Tou.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
