import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '../router'

const API_URL = process.env.VUE_APP_API_URL
const SAML_LOGIN_URL = process.env.VUE_APP_SAML_LOGIN_URL
const LOCAL_LOGIN_URL = process.env.VUE_APP_LOCAL_LOGIN_URL
const LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL

const receiveMessage = event => {
  // do we trust? might be different from what we originally opened
  const arr = window.location.href.split('/')
  const myOrigin = arr[0] + '//' + arr[2]
  if (event.origin !== myOrigin) {
    return
  }
  const { data } = event
  if (data === 'fhpc-popup') {
    window.removeEventListener('message', receiveMessage)
    store.dispatch('auth/ping')
  }
}

let windowObjectReference = null
let previousUrl = null

const openInWindow = (url, name) => {
  window.removeEventListener('message', receiveMessage)
  const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures)
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference.focus()
  } else {
    windowObjectReference.focus()
  }
  window.addEventListener('message', receiveMessage, false)
  previousUrl = url
}

class AuthService {
  samlLogin (organization = null) {
    if (organization == null) {
      openInWindow(SAML_LOGIN_URL, 'fhpc-auth')
    } else {
      openInWindow(SAML_LOGIN_URL + '?organization=' + organization, 'fhpc-auth')
    }
  }

  localLogin (username, password) {
    if (username == null || password == null) return

    const credentials = {
      username: username,
      password: password
    }
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    }

    return axios.post(LOCAL_LOGIN_URL, qs.stringify(credentials), config)
      .then(response => {
        if (response.data.error) {
          console.log('Unauthorized')
          return 'Unauthorized'
        } else if (response.data.success) {
          console.log('Success')
          store.dispatch('auth/ping')
          return 'Success'
        }
      })
      .catch(error => {
        console.log(error)
        return 'Error'
      })
  }

  logout () {
    openInWindow(LOGOUT_URL, 'fhpc-auth')
    router.push({ path: '/' })
  }

  ping () {
    return axios.get(API_URL + 'ping', { withCredentials: true }).then(
      response => {
        return response.data
      }
    )
  }

  replay (config) {
    return axios.request(config)
  }
}

export default new AuthService()
