import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/auth.module'
import manager from '@/store/modules/manager'
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pendingChanges: []
  },
  mutations: {
    addPendingChange (state, pendingChange) {
      const pendingChangeIdx = state.pendingChanges.indexOf(pendingChange)
      if (pendingChangeIdx < 0) {
        state.pendingChanges.push(pendingChange)
      }
    },
    removePendingChange (state, pendingChange) {
      const pendingChangeIdx = state.pendingChanges.indexOf(pendingChange)
      if (pendingChangeIdx >= 0) {
        state.pendingChanges.splice(pendingChangeIdx, 1)
      }
    }
  },
  actions: {
  },
  modules: {
    auth,
    manager,
    user
  }
})
