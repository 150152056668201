<template>
  <div class="login" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("login.title") }}</h1>
    <b-card-group deck>
      <b-card>
        <template #header>
          {{ $t('comp.login.saml.label') }}
          <b-iconstack v-if="!showLocal" class="float-right" font-scale="1.5" @click="openLocal()">
            <b-icon stacked icon="circle"></b-icon>
            <b-icon stacked scale="0.8" icon="key"></b-icon>
          </b-iconstack>
        </template>
        <b-card-text>{{ $t('comp.login.saml.description') }}</b-card-text>

        <b-button @click="samlLogin('fau')" variant="primary" class="m-1">
          {{ $t('comp.login.saml.fau.label') }}
        </b-button>
        <b-button @click="samlLogin('dfn')" variant="primary" class="m-1">
          {{ $t('comp.login.saml.dfn.label') }}
        </b-button>
      </b-card>

      <b-card v-if="showLocal">
        <template #header>
          {{ $t('comp.login.local.label') }}
          <b-iconstack class="float-right" font-scale="1.5" @click="closeLocal()">
            <b-icon stacked icon="circle"></b-icon>
            <b-icon stacked scale="0.8" icon="key-fill"></b-icon>
          </b-iconstack>
        </template>
        <b-card-text>{{ $t('comp.login.local.description') }}</b-card-text>
        <b-form>
          <b-form-group :label="$t('comp.login.local.username.label')"
                        :description="$t('comp.login.local.username.description')">
            <b-form-input id="username" name="username" v-model="username" type="text" autocomplete="off" trim/>
          </b-form-group>
          <b-form-group :label="$t('comp.login.local.password.label')"
                        :description="$t('comp.login.local.password.description')">
            <b-form-input id="password" name="password" v-model="password" type="password" autocomplete="off" trim/>
          </b-form-group>

          <b-button @click="localLogin" type="submit" variant="primary">
            {{ $t('comp.login.button.label') }}
          </b-button>
        </b-form>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Login',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  data () {
    return {
      showLocal: false,
      username: '',
      password: '',
      isLoading: ''
    }
  },
  methods: {
    samlLogin (organization) {
      if (organization === 'dfn') {
        this.$store.dispatch('auth/confirmUnauthorizedRequest')
        AuthService.samlLogin()
      } else {
        this.$store.dispatch('auth/confirmUnauthorizedRequest')
        AuthService.samlLogin(organization)
      }
    },
    localLogin (event) {
      event.preventDefault()
      this.$store.dispatch('auth/confirmUnauthorizedRequest')
      AuthService.localLogin(this.username, this.password)
        .then(status => {
          if (status === 'Success') {
            this.makeToast(
              this.$i18n.t('comp.login.success.description'),
              this.$i18n.t('comp.login.success.title'),
              'info'
            )
          } else {
            this.makeToast(
              this.$i18n.t('comp.login.error.description'),
              this.$i18n.t('comp.login.error.title'),
              'danger'
            )
          }
        })
        .catch(error => {
          this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message }),
            this.$i18n.t('result.error.title'),
            'danger')
        })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    openLocal () {
      this.showLocal = true
    },
    closeLocal () {
      this.showLocal = false
    }
  }
}
</script>

<style scoped>
</style>
