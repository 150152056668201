import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'accounts'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class AccountService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  listForProject (params = {}) {
    params.view = this.view
    params.type = 'listForProject'
    return DefaultApiService.list(ENDPOINT, params)
  }

  listForUser (params = {}) {
    params.view = this.view
    params.type = 'listForUser'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  buildTokenForCC (id, params = {}) {
    params.view = this.view
    params.type = 'buildTokenForCC'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  buildTokenForJH (id, tokenTarget = '', params = {}) {
    params.view = this.view
    params.type = 'buildTokenForJH'
    params.tokenTarget = tokenTarget
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  updateMany (data, params = {}) {
    params.view = this.view
    params.type = 'updateMany'
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default AccountService
export const accountServiceForUserView = new AccountService(VIEW_USER)
export const accountServiceForManagerView = new AccountService(VIEW_MANAGER)
export const accountServiceForAdvisorView = new AccountService(VIEW_ADVISOR)
export const accountServiceForSupportView = new AccountService(VIEW_SUPPORT)
export const accountServiceForAdminView = new AccountService(VIEW_ADMIN)
