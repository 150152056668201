import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'consent'

class ConsentService {
  give (data = {}, params = {}) {
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  withdraw (id, params = {}) {
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default new ConsentService()
