import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './mixins/title.mixin'
import axios from 'axios'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.mixin(titleMixin)

// workaround for sync modifier, see also https://github.com/vuejs/vue/issues/6428#issuecomment-358327547
Vue.prototype.$emitCase = function (name, ...args) {
  const camelCaseName = name.toLowerCase().replace(/-(.)/g,
    (_, g) => g.toUpperCase())
  this.$emit(name, ...args)
  this.$emit(camelCaseName, ...args)
}

axios.interceptors.response.use(
  // Return a successful response back to the calling service
  response => {
    if (!store.state.auth.status.serverOnline) {
      store.commit('auth/serverOnline')
    }
    return response
  },
  // Return any error which is not due to authentication back to the calling service
  error => {
    if (error.response) {
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    } else {
      if (store.state.auth.status.serverOnline) {
        store.commit('auth/serverOffline')
      }
      console.log('Network error')
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    console.log('unauthorizedRequest')
    return store.dispatch('auth/createUnauthorizedRequest', error.config)
  }
)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
