var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.language === 'de')?_c('div',[_c('p',[_vm._v(" Es ist mir bewusst, dass wissenschaftliche Ergebnisse, die durch die Nutzung von Hochleistungsrechnern erzielt werden, den nationalen, europäischen und internationalen Exportkontroll-Verordnungen unterliegen können. ")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('p',[_vm._v("Im Speziellen:")]),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',[_c('p',[_vm._v(" I am aware that scientific results obtained through the use of high-performance computers may be subject to national, European and international export control regulations. ")]),_vm._m(6),_vm._m(7),_vm._m(8),_c('p',[_vm._v("Particularly:")]),_vm._m(9),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Hiermit erkläre ich, dass ich mich mit der Verordnung (EU) 2021/821 ("),_c('a',{attrs:{"href":"https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&from=DE","rel":"nofollow noreferrer noopener","target":"_blank"}},[_vm._v("https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&from=DE")]),_vm._v(") des Europäischen Parlamentes und des Rates vom 20. Mai 2021 über eine Unionsregelung für die Kontrolle der Ausfuhr, der Vermittlung, der technischen Unterstützung der Durchfuhr und der Verbringung betreffend Güter mit doppeltem Verwendungszweck, einschließlich deren Anhang (insbesondere Anhang I) auseinander gesetzt habe. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Ich versichere, dass ich die aktuellen Embargo-Listen ("),_c('a',{attrs:{"href":"https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html","rel":"nofollow noreferrer noopener","target":"_blank"}},[_vm._v("https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html")]),_vm._v(") kontrolliert habe: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Ich habe nachgeprüft, dass die Ergebnisse, die in meinen Projekten gewonnen werden und die Hochleistungsrechner-Ressourcen des NHR@FAU nutzen, keiner EU-Verordnung zu Gütern mit doppeltem Verwendungszweck unterliegen. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Ich versichere, dass Anhang I der EU-Dual-Use Verordnung Nr. 2021/821 und seine Aktualisierungen keines der in den Projekten erzielten Ergebnisse umfasst. (Achtung: Die Anhänge (Güterlisten) der EU-Dual-Use-Verordnung werden jährlich am Anfang des Jahres angepasst!) ")]),_c('li',[_vm._v(" Ich versichere, dass diese Ergebnisse keinerlei Bezug zu den im Artikel 4 Absatz 1 der EU-Dual-Use-Verordnung Nr. 2021/821 und seinen Aktualisierungen haben. ")]),_c('li',[_vm._v(" Ich versichere, dass, wenn ich Staatsbürger eines Landes mit Waffenembargo bin, die Ergebnisse mit keinerlei militärischer Nutzung, wie im Artikel 4 Absätze 1 und 3 oder im Artikel 4 Absatz 2 der EU-Dual-Use-Verordnung Nr. 2021/821 und seinen Aktualisierungen beschrieben, in Zusammenhang stehen. ")]),_c('li',[_vm._v(" Ich versichere, dass die Ergebnisse keine Embargo-Verordnung verletzen. Die Systeme von NHR@FAU dürfen insbesondere nicht für Projekte mit russischen Kooperationspartnern genutzt werden. ")]),_c('li',[_vm._v(" Sofern ich PI eines Projekts bin, sorge ich dafür, dass auch alle Leute in meinem HPC-Projekt die Exportkontroll-Verordnungen einhalten. ")]),_c('li',[_vm._v(" Sofern ich PI oder Technischer Kontakt eines Projekts bin, weise ich neue Projektmitglieder auf die Exportkontroll-Vorschriften hin, bevor ich ihnen eine Account-Einladung schicke. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ich werde dem NHR@FAU jegliche relevante Änderung in Bezug auf das aktuelle Forschungsgebiet melden.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Weitere Informationen zum Thema Exportkontrolle finden Sie auf der FAU Webseite zur Exportkontrolle ("),_c('a',{attrs:{"href":"https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/","rel":"nofollow noreferrer noopener","target":"_blank"}},[_vm._v("https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/")]),_vm._v(") oder dem Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA) ("),_c('a',{attrs:{"href":"https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html","rel":"nofollow noreferrer noopener","target":"_blank"}},[_vm._v("https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html")]),_vm._v("). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" I hereby declare that I have familiarized myself with the regulation (EU) 2021/821 ("),_c('a',{attrs:{"rel":"nofollow noreferrer noopener","href":"https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&from=DE","target":"_blank"}},[_vm._v("https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&from=DE")]),_vm._v(") of the European Parliament and Council of May 20, 2021 on a Union regulation for the control of exports, brokering, technical support for transit and shipment of dual-use items, including the Annex (in particular Annex I). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" I assure that I have checked the current embargo lists ("),_c('a',{attrs:{"rel":"nofollow noreferrer noopener","href":"https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html","target":"_blank"}},[_vm._v("https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html")]),_vm._v("): ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" I have verified that the results obtained in my projects using NHR@FAU's high-performance computing resources are not subject to any EU regulation on dual-use items. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" I certify that Annex I of the EU Dual-Use Regulation No. 2021/821 and its updates does not include any of the results obtained in the projects. (Attention: The appendices (lists of goods) of the EU Dual-Use Regulation are adjusted annually at the beginning of the year!) ")]),_c('li',[_vm._v(" I certify that these results have no relation to those in Article 4 Paragraph 1 of the EU Dual-Use Regulation No. 2021/821 and its updates. ")]),_c('li',[_vm._v(" I certify that if I am a citizen of a country with an arms embargo, the results will not be associated with any military use as referred to in Article 4 paragraphs 1 and 3 or in Article 4 paragraph 2 of the EU Dual-Use Regulation No. 2021/821 and its updates. ")]),_c('li',[_vm._v(" I assure that the results do not violate any embargo regulation. In particular, the NHR@FAU systems may not be used for projects with Russian cooperation partners. ")]),_c('li',[_vm._v(" If I am the PI of a project, I make sure that all people in my HPC project comply with the export control regulations.")]),_c('li',[_vm._v(" If I am the PI or Technical Contact of a project, I make new project members aware of the export control regulations before sending them an account invitation. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("I will report any relevant change related to the current research area to the NHR@FAU.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Further information on the subject of export controls can be found on the FAU website on export controls ("),_c('a',{attrs:{"rel":"nofollow noreferrer noopener","href":"https://www.intern.fau.de/Household-und-finances/exportcontrol/","target":"_blank"}},[_vm._v("https://www.intern.fau.de/Household-und-finances/exportcontrol/")]),_vm._v(") or the Federal Office of Economics and Export Control (BAFA) ("),_c('a',{attrs:{"rel":"nofollow noreferrer noopener","href":"http://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html","target":"_blank"}},[_vm._v("https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html")]),_vm._v("). ")])
}]

export { render, staticRenderFns }