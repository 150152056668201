import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'projects'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class ProjectService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  select (params = {}) {
    params.view = this.view
    params.type = 'select'
    return DefaultApiService.list(ENDPOINT, params)
  }

  selectUnixGroups (params = {}) {
    params.view = this.view
    params.type = 'unixGroupSelect'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getValidity (id, params = {}) {
    params.view = this.view
    params.type = 'getValidity'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default ProjectService
export const projectServiceForUserView = new ProjectService(VIEW_USER)
export const projectServiceForManagerView = new ProjectService(VIEW_MANAGER)
export const projectServiceForAdvisorView = new ProjectService(VIEW_ADVISOR)
export const projectServiceForSupportView = new ProjectService(VIEW_SUPPORT)
export const projectServiceForAdminView = new ProjectService(VIEW_ADMIN)
