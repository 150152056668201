import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'usages'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class UsageService {
  constructor (view) {
    this.view = view
  }

  /* Get Usages */

  getCurrentForProject (id, params = {}) {
    params.view = this.view
    params.type = 'project'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getCurrentForAccount (id, params = {}) {
    params.view = this.view
    params.type = 'account'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getCurrentForUser (id, params = {}) {
    params.view = this.view
    params.type = 'user'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getDataForBarplot (id, params = {}) {
    params.view = this.view
    params.type = 'barplot'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getDataForPieplot (id, params = {}) {
    params.view = this.view
    params.type = 'pieplot'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getDataForProgress (id, params = {}) {
    params.view = this.view
    params.type = 'projectTable'
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  getUsageStats (data, params = {}) {
    params.view = this.view
    return DefaultApiService.request(ENDPOINT, data, params)
  }
}

export default UsageService
export const usageServiceForUserView = new UsageService(VIEW_USER)
export const usageServiceForManagerView = new UsageService(VIEW_MANAGER)
export const usageServiceForAdvisorView = new UsageService(VIEW_ADVISOR)
export const usageServiceForSUpportView = new UsageService(VIEW_SUPPORT)
export const usageServiceForAdminView = new UsageService(VIEW_ADMIN)
