import { loadComponentLanguageAsync, messagesAvailable } from '@/i18n'

export const i18nMixin = {
  watch: {
    '$i18n.locale': function () {
      loadComponentLanguageAsync(this).finally()
    }
  },
  computed: {
    messagesAvailable () {
      return messagesAvailable(this)
    }
  },
  created () {
    loadComponentLanguageAsync(this).finally()
  }
}
